import { defineStore } from 'pinia'

export const useStore = defineStore('store', {
    state: () => {
        return {
            count: 0,
            screenWidth: '0',
            screenWidth: 0,
            scrollHeight: 0,
            scrollHeight_before: 0,
            isPC: true
        }
    },
    actions: {
        setScreenWidth(payload) {
            this.screenWidth = payload
            if (payload >= 1200) {
                this.isPC = true
            }
            else {
                this.isPC = false
            }
        },
        setScrollHeight(payload) {
            this.scrollHeight = payload
        },
        setScrollHeight_before() {
            this.scrollHeight_before = this.scrollHeight
        },
    }
})