import { createRouter, createWebHistory } from 'vue-router'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: "Home",
    component: () => import("../views/Home/Home.vue"),
    meta: {
      metaInfo: {
        title: "Fusteel Co., Ltd.",
        keywords: "home",
        description: "Home:Fusteel provides the highest quality service and the best stainless steel rebars, steel strands, dowel bars and so on.We are world's leading functional steel solution provider,and stainless steel rebar producer."
      }
    },
  },
  {
    path: "/stainless-steel-rebar",
    name: "Stainless-steel-rebar",
    component: () => import("../views/StainlessSteelRebar/StainlessSteelRebar.vue"),
    meta: {
      metaInfo: {
        title: "Stainless Steel Rebar-Fusteel Co., Ltd.",
        keywords: "stainless steel rebar",
        description: "Stainless steel rebar:Fusteel is the largest stainless steel rebar manufacturer in the world.We will provide the best stanless steel rebars for client.Our products satisfy BS 6744 and ASTM A955/A955M standards.We offers variable stainless steel products such as rebar, dowels, mesh and coupler.Full range of diameters from 8mm to 50mm. "
      }

    },
  },

  {
    path: "/stainless-steel-strand",
    name: "Stainless-steel-strand",
    component: () => import("../views/StainlessSteelStrand/StainlessSteelStrand.vue"),
    meta: {
      metaInfo: {
        title: "Stainless Steel Strand-Fusteel Co., Ltd.",
        keywords: "stainless steel strand",
        description: "Stainless steel strand:Fusteel stainless steel strand is the world first steel strand made of duplex stainless steel with super corrosion resistance property, tensile strength grade of 1860 Mpa and low relaxation."
      }
    },
  },
  {
    path: "/dowel-bar",
    name: "dowel bar",
    component: () => import("../views/StainlessSteelDowelBar/StainlessSteelDowelBar.vue"),
    meta: {

      metaInfo: {
        title: "Stainless Steel Dowel Bar-Fusteel Co., Ltd.",
        keywords: "stainless steel dowel bar",
        description: "Stainless steel dowel bar:Fusteel Co., Ltd. provides the best in product, service and support for all of your dowel bar needs.And we have both stainless steel dowel bar and epoxy coated dowel bar."
      }
    }
  },
  {
    path: "/pt-bar",
    name: "pt bar",
    component: () => import("../views/PtBar/PtBar.vue"),
    meta: {
      metaInfo: {
        title: "Stainless Steel Post-Tensioning Bar-Fusteel Co., Ltd.",
        keywords: "stainless steel post-tensionging bar",
        description: "The FUSTEELX stainless steel post-tensioning system is engineered from heat-treated 17-4PH stainless steel,ensuring exceptional strength exceeding 1030MPa."
      }
    }
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/Products/Products.vue"),
    meta: {
      metaInfo: {
        title: "Products-Fusteel Co., Ltd.",
        keywords: "stainless steel products",
        description: "Stainless steel products: Fusteel provides the highest quality service and provides the best stainless steel bars, steel strands, dowel bars and so on"
      }
    },
  },
  {
    path: "/about-us",
    name: "about-us",
    component: () => import("../views/AboutUs/AboutUs.vue"),
    meta: {
      metaInfo: {
        title: "About Us-Fusteel Co., Ltd.",
        keywords: "about Fusteel",
        description: "About: Fusteel Co., Ltd. is the leader of long steel procuts since 1991.We have the advantage of mill direct sale,one-step solution and accelerated delivery.We produce 2,000,000 tons high performance special steel per year,and we have absolute strength to sove various needs of customers."
      }
    }
  },
  {
    path: "/sma",
    name: "Sma",
    component: () => import("../views/SMA/Sma.vue"),
    meta: {
      metaInfo: {
        title: "SMA-Fusteel Co., Ltd.",
        keywords: "Fe-Shape Memory Materials",
        description: "Fe-Shape Memory Materials: Shape memory alloys are a unique class of alloys that have ability to 'remember' their shape and are able to return to that shape even after being bent.And Fusteel provides the best Fe-shape memory materials products"
      }
    }
  },
  {
    path: "/zero-magnetic",
    name: "zero-Magnetic",
    component: () => import("../views/NonMagnet/MagnetFree.vue"),
    meta: {
      metaInfo: {
        title: "Zero-Magnetic-Fusteel Co., Ltd.",
        keywords: "Zero-Magnetic steel",
        description: "Zero-Magnetic steel: Common zeromagnetic steels in the industry include zeromagnetic structural steel and zeromagnetic stainless steel, such as 45Mn17Al3, whose remanence rate is usually 1000nT.Fusteel Zero-magnet steel rebars have a remanence rate lower than 1nT. They are special zeromagnetic steels designed for extremely stringent remanence requirements and extremely weak magnetic field environments."
      }
    },
  },
  {
    path: "/sustainability",
    name: "Sustainability",
    component: () => import("../views/Sustainability/Sustainability.vue"),
    meta: {
      metaInfo: {
        title: "Sustainability-Fusteel Co., Ltd.",
        keywords: "sustainability",
        description: "Sustainability: Fusteel has our own photovoltaic power plant.Our solar system generate 40,000,000 KWh per year,which could charge 800,000 Tesla Model 3 Perfomance"
      }
    }
  },
  {
    path: "/projects",
    name: "Projects",
    component: () => import("../views/Projects/Projects.vue"),
    meta: {
      metaInfo: {
        title: "Projects-Fusteel Co., Ltd.",
        keywords: "projects",
        description: "Projects: In the past ten years, Fusteel has provided materials for various projects and participated in the construction of bridges, airports and other facilities"
      }
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/ContactUs/Contact.vue"),
    meta: {

      metaInfo: {
        title: "Contact-Fusteel Co., Ltd.",
        keywords: "contact",
        description: "Contact:How to contact us.And we will reply you within 12 hours."
      }
    },
  },
  {
    path: '/download/:name',
    name: "download",
    component: () => import("../views/Uploads.vue"),
    meta: {
      metaInfo: {
        title: "Download-Fusteel Co., Ltd.",
        keywords: "Fusteel Production pdf and description",
        description: "downloading files: Fusteel Co., Ltd. is the leader of long steel procuts since 1991.We have the advantage of mill direct sale,one-step solution and accelerated delivery.We produce 2,000,000 tons high performance special steel per year,and we have absolute strength to sove various needs of customers."
      }
    }
  },
  {
    path: "/warranty_book",
    name: "warranty book",
    component: () => import("../views/WarrantyBook.vue"),
    meta: {
      metaInfo: {
        title: "电子质保书平台-Fusteel Co., Ltd.",
        keywords: "电子 线上 质保书",
        description: "该页面为FusteelX电子质保书平台，用于展示产品质保书。"
      }
    }
  },
  {
    path: "/question",
    name: "question",
    component: () => import("../views/Question.vue"),
    meta: {
      metaInfo: {
        title: "QA-Fusteel Co., Ltd.",
        keywords: "QA Fusteel rebar dowelbar",
        description: "QA: Fusteel Co., Ltd. is the leader of long steel procuts since 1991.We have the advantage of mill direct sale,one-step solution and accelerated delivery.We produce 2,000,000 tons high performance special steel per year,and we have absolute strength to sove various needs of customers."
      }
    }
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/home'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
  if (to.meta.metaInfo) {
    document.title = to.meta.metaInfo.title
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.metaInfo.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.metaInfo.description)
  }
  next();
});

export default router
